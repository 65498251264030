export const casino = [
  { provider: 'dg', code: 'none', image: '/static/images/games/bf/02.png', name: 'DG' },
  { provider: 'we', code: 'none', image: '/static/images/games/bf/03.png', name: 'WE Entertainment' },
  { provider: 'eg', code: 'none', image: '/static/images/games/bf/04.png', name: 'Evolution Gaming' },
  { provider: 'xg', code: 'none', image: '/static/images/games/bf/07.png', name: 'Xtream Gaming' },
  { provider: 'sexy', code: 'none', image: '/static/images/games/bf/05.png', name: 'Sexy' },
  { provider: 'wm', code: 'none', image: '/static/images/games/bf/06.png', name: 'WM' },
  { provider: 'ag', code: 'none', image: '/static/images/games/bf/08.png', name: 'Asia Gaming' },
  { provider: 'sa', code: 'none', image: '/static/images/games/bf/09.png', name: 'SA Gaming' }
]

export const slots = [
  { provider: 'pp', code: 'disable', image: '/static/images/games/bf/14.png', name: 'PragmaticPlay' },
  { provider: 'ng', code: 'disable', image: '/static/images/games/bf/15.png', name: 'Naga Games' },
  { provider: 'bs', code: 'enable', image: '/static/images/games/bf/34.png', name: 'BETSOFT' },
  { provider: 'prs', code: 'enable', image: '/static/images/games/bf/24.png', name: 'Print Studios' },
  { provider: 'elk', code: 'enable', image: '/static/images/games/bf/25.png', name: 'ELK Studios' },
  { provider: 'waz', code: 'enable', image: '/static/images/games/bf/13.png', name: 'Wazdan' },
  { provider: 'ps', code: 'none', image: '/static/images/games/bf/16.png', name: 'PlayStar', in: true },
  { provider: '1x2', code: 'enable', image: '/static/images/games/bf/17.png', name: '1x2 Gaming' },
  { provider: 'hak', code: 'enable', image: '/static/images/games/bf/36.png', name: 'Hacksaw Gaming' },
  { provider: 'kg', code: 'disable', image: '/static/images/games/bf/26.png', name: 'KA Gaming' },
  { provider: 'fng', code: 'enable', image: '/static/images/games/bf/27.png', name: 'Fantasma Games' },
  { provider: 'nge', code: 'enable', image: '/static/images/games/bf/12.png', name: 'NetGames Ent' },
  { provider: 'pug', code: 'enable', image: '/static/images/games/bf/18.png', name: 'Push Gaming' },
  { provider: 'ga', code: 'enable', image: '/static/images/games/bf/19.png', name: 'Game Art' },
  { provider: 'png', code: 'enable', image: '/static/images/games/bf/35.png', name: 'Play n Go' },
  { provider: 'nlc', code: 'enable', image: '/static/images/games/bf/28.png', name: 'Nolimit City' },
  { provider: 'tk', code: 'enable', image: '/static/images/games/bf/29.png', name: 'Thunderkick' },
  { provider: 'ygg', code: 'enable', image: '/static/images/games/bf/11.png', name: 'Yggdrasil' },
  { provider: 'qs', code: 'enable', image: '/static/images/games/bf/20.png', name: 'Quickspin' },
  { provider: 'hab', code: 'enable', image: '/static/images/games/bf/21.png', name: 'Habanero' },
  { provider: 'rlx', code: 'enable', image: '/static/images/games/bf/37.png', name: 'Relax Gaming' },
  { provider: 'ds', code: 'enable', image: '/static/images/games/bf/30.png', name: 'Dragoon Soft' },
  { provider: 'red', code: 'enable', image: '/static/images/games/bf/31.png', name: 'Red Tiger' },
  { provider: 'bng', code: 'enable', image: '/static/images/games/bf/39.png', name: 'Booongo' },
  { provider: 'ids', code: 'enable', image: '/static/images/games/bf/22.png', name: 'Iron Dog' },
  { provider: 'kgl', code: 'enable', image: '/static/images/games/bf/23.png', name: 'Kalamba Games' },
  { provider: 'netent', code: 'enable', image: '/static/images/games/bf/23.png', name: 'NetEnt' },
  { provider: 'ttg', code: 'none', image: '/static/images/games/bf/38.png', name: 'TTG', in: true },
  { provider: 'pg', code: 'none', image: '/static/images/games/bf/10.png', name: 'PG', in: true },
  { provider: 'bpg', code: 'enable', image: '/static/images/games/bf/33.png', name: 'Blueprint Gaming' },
  { provider: 'mav', code: 'enable', image: '/static/images/games/bf/32.png', name: 'Maverick' }
]

export const spots = [
  { provider: 'bfs', code: 'none', image: '/static/images/games/bf/42.png', name: 'BF Sports' },
  { provider: 'ufa', code: 'none', image: '/static/images/games/bf/43.png', name: 'UFA BET' }
]

export const lotto = [
  { provider: 'e10', code: 'none', image: '/static/images/games/bf/42.png', name: 'BF Sports' }
]
