'use client'

import { useQuery } from '@tanstack/react-query'
import Slider from 'react-slick'

import axios from '@/services/axios'

export function BannerComponent() {
  // __FETCHER's
  const { data: responseData } = useQuery<any[]>({
    queryKey: ['banners'],
    queryFn: async () => {
      const r = await axios.get(`/v1/activity/slide`)
      return r?.data?.data || []
    },
    refetchOnWindowFocus: false
  })

  // __RENDER
  if (!responseData?.length) return null
  return (
    <div className='ui--home-banner'>
      <div className='container'>
        <Slider autoplay infinite initialSlide={0} speed={800} slidesToShow={1} slidesToScroll={1}>
          {responseData.map((record, index) => (
            <div className='slide' key={`.slide-${index}`}>
              <img
                className='size-full max-h-96 min-h-56 w-full object-contain object-center'
                src={record?.url_images || '/static/images/banner-1.png'}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
